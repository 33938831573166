import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "perawatan";
const modelPath = `/${modelName}/`;

class Perawatan extends ModelBase {
  constructor() {
    let model = {
      id: null,
      pembuat: "",
      pembuat_id: null,
      pasien: {id: null, nama: "", no_rm: ""},
      kode_bill: "",
      tanggal_masuk: "",
      jenis_lokasi: {id: null, nama: ""},
      jenis_perawatan: "",
      aktivitas_set: [],
      extra_data: {}
    };
    super(modelName, model, [], [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.payLoadData = {};
  }

  getLoadData(data) {
    this.observables.payLoadData = {};
    let perawatan = super.getLoadData(data);
    for (let aktivitas of perawatan.aktivitas_set) {
      let aktivitasSet = aktivitas.aktivitas_list.filter(
        ak => !ak.verified
      );
      for (let akt of aktivitasSet) {
        this.observables.payLoadData[akt.id] = {verifikasi: "Belum"};
      }
    }
    return perawatan;
  }

  getUpdateUrl() {
    return `${APP_CONFIG.baseAPIURL}${modelPath}${this.observables.perawatan.id}/aktivitas-batch/`;
  }
  
  getPayload() {
    let payLoadKet = {};
    for (let aktivitas of this.observables.perawatan.aktivitas_set) {
      let aktivitasSet = aktivitas.aktivitas_list.filter(
        ak => !ak.verified && this.observables.payLoadData[ak.id]);
      for (let akt of aktivitasSet) {
        let ver = this.observables.payLoadData[akt.id];
        let aktKetList = akt.keterampilan_dict.filter(ak_ket => ak_ket.verifikasi == "Belum")
        for (let akt of aktKetList) {
          payLoadKet[akt.id] = ver;
        }
      }
    }
    let data = {data_set: payLoadKet};
    return data;
  }
}

export default Perawatan;