<template>
  <div class="klinis-detail">
    <div class="columns no-margin">
      <div class="column no-padding is-full">
        <div class="card">
          <header class="card-header has-background-primary">
            <div @click.stop="$router.go(-1)" class="card-header-icon">
              <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
            </div>
            <p class="card-header-title has-text-light">PENGELOLAAN KASUS</p>
            <router-link
              v-if="isLoaded && perawatan.extra_data.can_change"
              :to="{ name: 'klinis-change', params: { id: paramsId } }"
              class="card-header-icon has-text-light"
            >
              <b-icon class="has-text-light" size="is-small" icon="edit" />
            </router-link>
            <a
              class="card-header-icon"
              v-if="perawatan.extra_data.can_delete"
              @click.stop.prevent="confirmDelete"
            >
              <b-icon icon="trash" size="is-small" class="has-text-light" />
            </a>
          </header>
          <div class="card-content events-card">
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Kode Bill</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.kode_bill }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Pasien</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left is-capitalized">
                <router-link
                  v-if="perawatan.pasien.id"
                  :to="{ name: 'pasien-detail', params: { id: perawatan.pasien.id } }"
                >{{ perawatan.pasien.nama }}</router-link>
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">No RM</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.pasien.no_rm }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Rawat</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.jenis_perawatan }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Lokasi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.jenis_lokasi.nama }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Tanggal</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.tanggal_masuk }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Mahasiswa</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.pembuat }}</div>
            </div>
            <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isLoaded">
      <div class="columns no-margin">
        <div class="column no-padding is-full">
          <div class="card">
            <header class="card-header has-background-primary">
              <p class="card-header-title has-text-light">AKTIVITAS MAHASISWA</p>
              <template v-if="isPSPD">
              <router-link
                v-if="perawatan.jenis_perawatan == 'jalan'"
                :to="{ name: 'aktivitas-klinis-jalan-add', params: { perawatanId: paramsId } }"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="plus" />
              </router-link>
              <router-link
                v-else
                :to="{ name: 'aktivitas-klinis-inap-add', params: { perawatanId: paramsId } }"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="plus" />
              </router-link>
              </template>
              <a
                v-if="perawatan.extra_data.can_update_ver"
                class="card-header-icon"
                @click.stop.prevent="saveObjects()"
              >
                <b-icon icon="save" size="is-small" class="has-text-light" />
              </a>
            </header>
            <div class="card-content table-card-content">
              <table class="table is-fullwidth is-scrollable">
                <tbody>
                  <template v-if="perawatan.aktivitas_set.length != 0">
                    <template v-for="data in perawatan.aktivitas_set">
                      <tr :key="data.preceptor_id">
                        <td colspan="6">
                          {{ data.preceptor }}
                        </td>
                      </tr>
                      <tr class="has-background-white-bis" :key="data.preceptor">
                        <td class="fix-width-25">Mahasiswa</td>
                        <td>D</td>
                        <td>P</td>
                        <td>T</td>
                        <td>K</td>
                        <td v-if="perawatan.extra_data.can_update_ver">Verifikasi</td>
                      </tr>
                      <tr v-for="aktivitas in data.aktivitas_list" :key="aktivitas.id">
                        <td class="bg-white">
                          <small>{{ aktivitas.tanggal }}</small><br>
                          <router-link
                            :to="{ name: 'aktivitas-detail', params: {id: aktivitas.id} }"
                          >{{ aktivitas.pspd }}</router-link>
                          <a
                            v-show="aktivitas.foto_list.length > 0"
                            @click.stop.prevent="openGallery(aktivitas.foto_list)"
                          >
                            <fa
                              :icon="['fas', 'image']"
                              :title="'Tampilan gambar'"
                              :class="'icon is-pulled-right'"
                            ></fa>
                          </a>
                        </td>
                        <td>
                          <div v-if="aktivitas.diagnosis_dict.length > 0">
                            <b-field grouped group-multiline>
                              <komponen-aktivitas-tag
                                v-for="ak_diag in aktivitas.diagnosis_dict"
                                :key="ak_diag.id"
                                :id="ak_diag.id"
                                :namedUrl="'aktivitas-diagnosis-detail'"
                                :aktivitas="aktivitas"
                                :aktKomp="ak_diag"
                                :tagColor="getTagColor(aktivitas.pspd_id, data.preceptor_id)"
                              />
                            </b-field>
                          </div>
                        </td>
                        <td>
                          <div v-if="aktivitas.pemeriksaan_dict.length > 0">
                            <b-field grouped group-multiline>
                              <komponen-aktivitas-tag
                                v-for="ak_pemeriksaan in aktivitas.pemeriksaan_dict"
                                :key="ak_pemeriksaan.id"
                                :id="ak_pemeriksaan.id"
                                :namedUrl="'aktivitas-pemeriksaan-detail'"
                                :aktivitas="aktivitas"
                                :aktKomp="ak_pemeriksaan"
                                :tagColor="getTagColor(aktivitas.pspd_id, data.preceptor_id)"
                              />
                            </b-field>
                          </div>
                        </td>
                        <td>
                          <div v-if="aktivitas.tatalaksana_dict.length > 0">
                            <b-field grouped group-multiline>
                              <komponen-aktivitas-tag
                                v-for="ak_tatalaksana in aktivitas.tatalaksana_dict"
                                :key="ak_tatalaksana.id"
                                :id="ak_tatalaksana.id"
                                :namedUrl="'aktivitas-tatalaksana-detail'"
                                :aktivitas="aktivitas"
                                :aktKomp="ak_tatalaksana"
                                :tagColor="getTagColor(aktivitas.pspd_id, data.preceptor_id)"
                              />
                            </b-field>
                          </div>
                        </td>
                        <td>
                          <div v-if="aktivitas.keterampilan_dict.length > 0">
                            <b-field grouped group-multiline>
                              <komponen-aktivitas-tag
                                v-for="ak_keterampilan in aktivitas.keterampilan_dict"
                                :key="ak_keterampilan.id"
                                :id="ak_keterampilan.id"
                                :namedUrl="'aktivitas-keterampilan-detail'"
                                :aktivitas="aktivitas"
                                :aktKomp="ak_keterampilan"
                                :keterampilan="true"
                                :tagColor="getTagColor(aktivitas.pspd_id, data.preceptor_id)"
                              />
                            </b-field>
                          </div>
                        </td>
                        <td v-if="perawatan.extra_data.can_update_ver">
                          <template v-if="data.preceptor_id == userId && !aktivitas.verified">
                            <b-select v-model="payLoadData[aktivitas.id].verifikasi">
                              <option value="Belum">Belum</option>
                              <option value="Ya">Ya</option>
                              <option value="Tidak">Tidak</option>
                            </b-select>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td>
                        <div class="content has-text-grey has-text-centered">
                          <p>Belum ada aktivitas modul pada perawatan ini.</p>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import GLightbox from "glightbox";
import { mapGetters, mapState } from "vuex";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import Perawatan from "../models/perawatanDetail.js";

export default {
  name: "PerawatanDetail",
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  props: ['title'],
  components: {
    KomponenAktivitasTag: () =>
      import("@/apps/aktivitas/components/KomponenAktivitasTag.vue"),
  },
  beforeRouteLeave(to, from, next) {
    if (this.needConfirmation()) {
      this.showConfirmationDlg(
        () => {
          this.saveObjects(next);
        },
        () => next(false)
      );
    } else {
      next();
    }
  },
  data() {
    this.perawatanMdl = new Perawatan();
    this.objectMdl = this.perawatanMdl;
    let obv = this.perawatanMdl.getObservables();
    obv.paramsId = this.$route.params.id;
    this.objectMdl = this.perawatanMdl;
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin", "isPSPD", "isPreceptor"]),
    ...mapState("accounts", ["userId"])
  },
  methods: {
    fetchData() {
      this.perawatanMdl.load(this.paramsId);
    },
    getTagColor(pspd_id, preceptor_id) {
      return this.userId == pspd_id || this.userId == preceptor_id;
    },
    needConfirmation() {
      let vals = Object.values(this.payLoadData);
      let changed = vals.reduce((res, val) => res || val.verifikasi !== "Belum", false);
      return changed;
    },
    saveObjects(next) {
      if (!this.needConfirmation()) return;
      let onSaved = next ? next : this.fetchData;
      this.perawatanMdl.update(onSaved);
    },
    showConfirmationDlg(onConfirm, onCancel) {
      let dlg_params = {
        title: "Simpan Perubahan",
        message:
          "Yakin akan pindah halaman? Terdapat data yang belum tersimpan!",
        confirmText: "Simpan perubahan",
        type: "is-danger",
        hasIcon: true
      };
      if (onConfirm) dlg_params.onConfirm = onConfirm;
      if (onCancel) dlg_params.onCancel = onCancel;
      this.$buefy.dialog.confirm(dlg_params);
    },
    openGallery(foto_list) {
      this.glightbox.setElements(foto_list);
      this.glightbox.open();
    },
  },
  mounted() {
    this.glightbox = GLightbox();
  },
  updated() {
    this.glightbox.reload();
  }
};
</script>

<style scoped lang="scss">
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../core/assets/css/glightbox.css";
@media screen and (max-width: 768px) {
  .card-content {
    font-size: 0.9rem !important;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 1180px) {
  .columns {
    margin-right: unset !important;
  }
}

div.table-card-content {
  overflow-x: auto;
  padding: 0 !important;
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}
</style>